import React from 'react'
import { styled } from '../../../theme/index'
import TrackingUrlOutput from '../TrackingUrlOutput/TrackingUrlOutput'
import ShareActions from '../ShareActions/ShareActions'
import './LinkGeneratorForm.css';
import LinkGeneratorFormContent from "./LinkGeneratorFormContent";
import type { LinkGeneratorFormProps } from "../../../types/buildALinkTypes";

const Container = styled('div', {
  display: 'block',
  width: '100%',
  maxWidth: '100%',
  variants: {
    type: {
      linkGenerator: {
        '@bp5': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }
      },
      deepLink: {}
    }
  }
})

const OptionsUrl = styled('div', {
  marginTop: '20px',
  '@bp3': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
})

const Actions = styled('div', {
  marginTop: '40px',
  maxWidth: '100%',
  '@bp3': {
    maxWidth: '100%',
    marginRight: '12px'
  }
})

const LinkGeneratorForm: React.FC<LinkGeneratorFormProps> = ({
  brandId = 0,
  deeplinkUrl,
  enabledDeepLink = false,
  error = '',
  generatedLink = '',
  handler,
  handleSetLoading,
  hasAction = true,
  isBuildALink = false,
  isShort,
  parentPage = '',
  ready = false,
  setErrorMessages,
  type = 'linkGenerator',
  urlEmpty = false,
  urlNotMatch = false,
  ...props
}) => {

  const handleToggleLoading = (): void => {
    handleSetLoading?.();
  }

  const handleError = (): void => {
    setErrorMessages();
  }

  if (isBuildALink === true) {
    return (
      <div {...props} className="form-container">
        <LinkGeneratorFormContent
          brandId={brandId}
          enabledDeepLink={enabledDeepLink}
          error={error}
          generatedLink={generatedLink}
          handler={handler}
          handleSetLoading={handleSetLoading}
          hasAction={hasAction}
          isBuildALink={true}
          isShort={isShort}
          parentPage={parentPage}
          ready={ready}
          setErrorMessages={setErrorMessages}
          type={type}
          urlEmpty={urlEmpty}
          urlNotMatch={urlNotMatch}
        />
      </div>
    );
  }

  return (
    <>
      <Container type={type} {...props}>
        <LinkGeneratorFormContent
          brandId={brandId}
          enabledDeepLink={enabledDeepLink}
          error={error}
          deeplinkUrl={deeplinkUrl}
          generatedLink={generatedLink}
          handler={handler}
          handleSetLoading={handleSetLoading}
          hasAction={hasAction}
          isBuildALink={false}
          isShort={isShort}
          parentPage={parentPage}
          ready={ready}
          setErrorMessages={setErrorMessages}
          type={type}
          urlEmpty={urlEmpty}
          urlNotMatch={urlNotMatch}
        />
      </Container>

      {
        hasAction === true && ready === true &&
        <OptionsUrl className={type === 'linkGenerator' ? 'ThreePart' : ''}>
          <TrackingUrlOutput
            value={generatedLink}
            parentPage={parentPage}
          />
          <Actions>
            <ShareActions
              dealID={brandId}
              toCopy={generatedLink}
              column={true}
              icon={false}
              label="COPY"
              parentPage={parentPage}
              handleToggleLoading={handleToggleLoading}
              setErrorMessages={handleError}
            />
          </Actions>
        </OptionsUrl>
      }
    </>
  )
}
export default LinkGeneratorForm
