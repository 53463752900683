// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React from 'react';
import { styled, type ThemeProps } from './../../../theme/index';
import Tabs from './../../molecules/Tabs/Tabs';

import ToolsContent from './../../molecules/ToolsContent/ToolsContent';
import MobileAppContent from './../../molecules/MobileAppContent/MobileAppContent';
import BuildALinkContent from './../../molecules/BuildALinkContent/BuildALinkContent';
import BrowserExtensionContent from '../../molecules/BrowserExtensionContent/BrowserExtensionContent';

interface Props extends ThemeProps {
  defaultTab?: number;
}

const Container = styled('div', {
  display: 'block',
  maxWidth: '100%',
});

const Tools: React.FC<Props> = ({ defaultTab, ...props }) => {
  return (
    <Container {...props}>
      <Tabs
        defaultTab={defaultTab}
        tabs={[
          {
            label: 'Extension',
            content: <BrowserExtensionContent />,
          },
          {
            label: 'Build a Link',
            content: <BuildALinkContent />,
          },
          {
            label: 'Mobile App',
            content: <MobileAppContent />,
          },
          {
            label: 'Bookmarklet',
            content: <ToolsContent />,
          },
        ]}
        css={{
          marginTop: '42px',
        }}
      ></Tabs>
    </Container>
  );
};

export default Tools;
