import React from 'react';
import HubSpotForm from '../HubSpotForm/HubSpotForm';
import { hubSpotFormConfig } from '../../../data/hubSpotFormConfig';

const ContactContent: React.FC = () => {
  const formId = hubSpotFormConfig.contactSupportForm.formId;
  const portalId = hubSpotFormConfig.contactSupportForm.portalId;
  const region = hubSpotFormConfig.contactSupportForm.region;
  const targetId = hubSpotFormConfig.contactSupportForm.targetId;

  return (
    <section className="supportTabs__tabContent supportTabs__tabContent--contact">
      <h4 className="supportTabs__tabContentTitle">Contact Support</h4>
      <p className="supportTabs__tabContentSubtitle">Please submit your question below.</p>
      <p className="supportTabs__tabContentSubtitle">
        If you have additional images or videos that relate to this question and will help us
        understand it better, please attach those as well. This will allow our support team to
        respond quickly and more effectively.
      </p>
      <HubSpotForm
        className="supportTabs__tabContentForm"
        formId={formId}
        portalId={portalId}
        region={region}
        targetId={targetId}
      />
    </section>
  );
};

export default ContactContent;
