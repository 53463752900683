import React from 'react';
import HubSpotForm from '../HubSpotForm/HubSpotForm';
import { hubSpotFormConfig } from '../../../data/hubSpotFormConfig';

const RequestABrandContent: React.FC = () => {
  const formId = hubSpotFormConfig.requestABrandForm.formId;
  const portalId = hubSpotFormConfig.requestABrandForm.portalId;
  const region = hubSpotFormConfig.requestABrandForm.region;
  const targetId = hubSpotFormConfig.requestABrandForm.targetId;
  return (
    <section className="supportTabs__tabContent supportTabs__tabContent--requestABrand">
      <h4 className="supportTabs__tabContentTitle">Request a brand</h4>
      <p className="supportTabs__tabContentSubtitle">
        Let us know what brand you&apos;d like to see added to BrandCycle.
      </p>
      <HubSpotForm
        className="supportTabs__tabContentForm"
        formId={formId}
        portalId={portalId}
        region={region}
        targetId={targetId}
      />
    </section>
  );
};

export default RequestABrandContent;
