import React from 'react';
import HubSpotForm from '../HubSpotForm/HubSpotForm';
import { hubSpotFormConfig } from '../../../data/hubSpotFormConfig';

const MissingATransactionContent: React.FC = () => {
  const formId = hubSpotFormConfig.missingATransactionForm.formId;
  const portalId = hubSpotFormConfig.missingATransactionForm.portalId;
  const region = hubSpotFormConfig.missingATransactionForm.region;
  const targetId = hubSpotFormConfig.missingATransactionForm.targetId;
  return (
    <section className="supportTabs__tabContent supportTabs__tabContent--missingATransaction">
      <h4 className="supportTabs__tabContentTitle">Missing a transaction?</h4>
      <p className="supportTabs__tabContentSubtitle">
        Submit a Support Ticket below and we&apos;ll review the issue.
      </p>
      <p className="supportTabs__tabContentSubtitle">
        If you have additional files or images that relate to this sale and it&apos;s attribution,
        please attach them below. This will allow our support team to respond quickly and more
        effectively.
      </p>
      <HubSpotForm
        className="supportTabs__tabContentForm"
        formId={formId}
        portalId={portalId}
        region={region}
        targetId={targetId}
      />
    </section>
  );
};

export default MissingATransactionContent;
