import React, { useState, useEffect, useRef } from 'react'
import { styled } from "../../../theme/index";
import Input from '../../atoms/Input/Input';
import { Button } from '../../atoms/Button/Button';
import Checkbox from "../../atoms/Checkbox/Checkbox";
import CopyDisclaimer from "../../atoms/CopyDisclaimer/CopyDisclaimer";
import useCopy from "../../../hook/useCopy/useCopy";
import type { LinkGeneratorFormContentProps } from "../../../types/buildALinkTypes";

const InputsSection = styled('div', {
  display: 'block',
  flex: 2,
  label: {
    marginTop: '21px'
  },
  input: {
    marginTop: '1px',
    maxWidth: '100%'
  },
  variants: {
    type: {
      linkGenerator: {
        label: {
          marginTop: '20px'
        },
        input: {
          marginTop: '2px',
          maxWidth: '100%'
        }
      },
      deepLink: {}
    }
  }
})

const ButtonWrapper = styled('div', {
  width: '100%',
  variants: {
    type: {
      linkGenerator: {
        '@bp5': {
          width: '52%'
        }
      },
      deepLink: {}
    }
  }
})

const DeepLinkSection = styled('div', {
  cursor: 'pointer',
  flex: 2,
  variants: {
    type: {
      linkGenerator: {
        marginLeft: 0,
        '@bp3': {
          marginLeft: '12px'
        },
        '@bp5': {
          marginLeft: '48px'
        }
      },
      deepLink: {}
    }
  }
})

const FailWrapper = styled('div', {
  padding: '15px',
  color: '#a94442',
  borderColor: '#ebccd1',
  backgroundColor: '#f2dede',
  borderRadius: '2px',
  lineHeight: 1.428,
  fontWeight: '$bold',
  fontFamily: '$label'
})

const CheckboxOption = styled(Checkbox, {
  variants: {
    view: {
      linkGenerator: { padding: '24px 0 29px' },
      deepLink: {
        padding: '23px 12px 28px',
        label: {
          fontSize: '14px'
        }
      }
    }
  }
})

const LinkSection = styled('section', {
  display: 'grid',
  gridTemplateColumns: '50% 50%',
  justifyContent: 'start',
  gridGap: '1rem'
})

const YourLink = styled('input', {
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '12px',
  fontSize: '$size15',
  fontFamily: '$body',
  fontWeight: '$bold',
  lineHeight: 1.37,
  border: '1px solid $blue7',
  borderRadius: '1px',
  width: '100%',
  maxWidth: '100%',
  minHeight: '44px',
  maxHeight: 'fit-content',
  margin: '10px 0',
  color: '#666',
})

const LinkGeneratorFormContent: React.FC<LinkGeneratorFormContentProps> = ({
  deeplinkUrl,
  enabledDeepLink = false,
  error = '',
  isShort = true,
  isBuildALink = false,
  type = 'linkGenerator',
  handler
}) => {
  const [linkGenForm, setLinkGenForm] = useState({
    url: '',
    id: '',
    isShort,
    enabledDeepLink
  })
  const [copyLongLink, setCopyLongLink] = useState(false);
  const [copyShortLink, setCopyShortLink] = useState(false);
  const [copyAction] = useCopy();
  const buttonRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const handleChange = (field: string, value: string): void => {
    if (field === "isShort") {
      setLinkGenForm({ ...linkGenForm, ...{ isShort: linkGenForm.isShort !== true } })
    }
    else {
      setLinkGenForm({ ...linkGenForm, ...{ [field]: value } })
    }
  }

  let btnStyle: string | undefined;
  let errorStyle: string | undefined;
  if (isBuildALink) {
    btnStyle = "build-a-link-form";
    errorStyle = "errorStyle";
  }

  // adds "enter key submit" functionality to the create-a-link form
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.key === 'Enter') {
        const buttonElement = buttonRef.current?.querySelector('button');
        buttonElement?.click();
      }
    };

    const formElement = formRef.current;
    formElement?.addEventListener('keydown', handleKeyDown);

    return () => {
      formElement?.removeEventListener('keydown', handleKeyDown);
    };
  }, [type]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault(); // prevents page refresh on create-a-link click
  };

  return (
    <form
      className="linkGeneratorFormContent"
      ref={formRef}
      onSubmit={handleSubmit}
    >
      {
        (enabledDeepLink === "1" || enabledDeepLink === true) &&
        <>
          <InputsSection type={type}
          >
            {
              error !== '' && (
                <FailWrapper className={errorStyle}>
                  <span>{error}</span>
                </FailWrapper>
              )
            }
            <Input label="Destination URL" type="url" className="value" view={type} optional={false} value={linkGenForm.url}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange('url', e.target.value)
              }}
            />
            <Input label="Aff Tracking ID" type="text" className="value" view={type} optional={true}
              labelSpacedVariant={type === 'linkGenerator'} value={linkGenForm.id}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange('id', e.target.value)
              }}
            />
          </InputsSection>
          <DeepLinkSection type={type} className="links-section" >
            <CheckboxOption name="deepLinking" id="deepLinking" type="secondary" view={type} isAccept={linkGenForm.isShort}
              callback={(value: string) => { handleChange('isShort', value) }} >
              Create Short URL
            </CheckboxOption>
            <ButtonWrapper type={type} ref={buttonRef}>
              <Button
                type="linkGenerator"
                onClick={() => {
                  handler?.(linkGenForm);
                }}
                className={btnStyle}
              >
                CREATE DEEP LINK
              </Button>
            </ButtonWrapper>
          </DeepLinkSection>
        </>
      }
      {
        (enabledDeepLink === "0" || enabledDeepLink === false || enabledDeepLink === "") &&
        <InputsSection type={type}>
          {
            error !== '' && (
              <FailWrapper className={errorStyle}>
                <span>{error}</span>
              </FailWrapper>
            )
          }
          <h4>Unfortunately, this brand does not currently support deep linking.</h4>
          <LinkSection>
            <div style={{ fontSize: "85%" }}>Build a Homepage Short Link</div><br />
            <YourLink type="text" value={deeplinkUrl?.shortLink} readOnly={true}></YourLink>
            <Button type="action"
              onClick={() => {
                copyAction(deeplinkUrl?.shortLink ?? '');
                setCopyLongLink(true);
              }}
              style={{ marginTop: "1px", width: "50%" }}
            >
              COPY THIS LINK
              {copyLongLink && (
                <CopyDisclaimer
                  css={{
                    marginTop: '0.3rem'
                  }}
                >
                  Link Copied
                </CopyDisclaimer>
              )}
            </Button>
          </LinkSection>
          <LinkSection>
            <div style={{ fontSize: "85%" }}>Build a Homepage Full Link</div><br />
            <YourLink type="text" value={deeplinkUrl?.longLink} readOnly={true}></YourLink>
            <Button type="action"
              onClick={() => {
                copyAction(deeplinkUrl?.longLink ?? '');
                setCopyShortLink(true);
              }}
              style={{ marginTop: "1px", width: "50%" }}
            >
              COPY THIS LINK
              {copyShortLink && (
                <CopyDisclaimer
                  css={{
                    marginTop: '0.3rem'
                  }}
                >
                  Link Copied
                </CopyDisclaimer>
              )}
            </Button>
          </LinkSection>
        </InputsSection>
      }
    </form >
  );
}

export default LinkGeneratorFormContent;