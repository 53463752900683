export const hubSpotFormConfig = {
  contactSupportForm: {
    formId: process.env.REACT_APP_CONTACT_SUPPORT_FORM_ID ?? "",
    portalId: process.env.REACT_APP_HUBSPOT_FORM_PORTAL_ID ?? "",
    region: process.env.REACT_APP_HUBSPOT_FORM_REGION ?? "",
    targetId: process.env.REACT_APP_CONTACT_SUPPORT_TARGET_ID ?? "",
  },
  missingATransactionForm: {
    formId: process.env.REACT_APP_MISSING_A_TRANSACTION_FORM_ID ?? "",
    portalId: process.env.REACT_APP_HUBSPOT_FORM_PORTAL_ID ?? "",
    region: process.env.REACT_APP_HUBSPOT_FORM_REGION ?? "",
    targetId: process.env.REACT_APP_MISSING_A_TRANSACTION_TARGET_ID ?? "",
  },
  requestABrandForm: {
    formId: process.env.REACT_APP_REQUEST_A_BRAND_FORM_ID ?? "",
    portalId: process.env.REACT_APP_HUBSPOT_FORM_PORTAL_ID ?? "",
    region: process.env.REACT_APP_HUBSPOT_FORM_REGION ?? "",
    targetId: process.env.REACT_APP_REQUEST_A_BRAND_TARGET_ID ?? "",
  },
};
