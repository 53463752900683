import React from 'react';
import { styled } from '../theme/index';
import HubSpotForm from '../components/molecules/HubSpotForm/HubSpotForm';
import { hubSpotFormConfig } from '../data/hubSpotFormConfig';

const Container = styled('div', {
  maxWidth: '100%',
});

export const RequestABrandBMK: React.FC = () => {
  const formId = hubSpotFormConfig.requestABrandForm.formId;
  const portalId = hubSpotFormConfig.requestABrandForm.portalId;
  const region = hubSpotFormConfig.requestABrandForm.region;
  const targetId = hubSpotFormConfig.requestABrandForm.targetId;

  return (
    <Container>
      <h1
        className="text-center spacer-top-md"
        style={{ textTransform: 'uppercase', textAlign: 'center' }}
      >
        Suggest a retailer
      </h1>

      <p
        style={{
          color: '#595F7B',
          fontWeight: 'bold',
          fontSize: '1em',
          fontFamily: 'Source Sans Pro, Helvetica, Arial, sans-serif',
          textTransform: 'uppercase',
          textAlign: 'center',
        }}
      >
        This retailer is currently not part of BrandCycle.
      </p>

      <p style={{ color: '#826c9d', fontSize: '1em', textAlign: 'center' }}>
        Please submit the form below to let us know you&apos;re interested in this retailer.
      </p>

      <HubSpotForm
        className="supportTabs__tabContentForm"
        formId={formId}
        portalId={portalId}
        region={region}
        targetId={targetId}
      />
    </Container>
  );
};
