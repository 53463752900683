import React from 'react';
import { styled } from '../../../theme/index';
import Tabs from '../../molecules/Tabs/Tabs';
import type { SupportProps } from '../../../types/supportTabsTypes';
import FaqsContent from '../../molecules/FaqsContent/FaqsContent';
import ContactContent from '../../molecules/ContactContent/ContactContent';
import RequestABrandContent from '../../molecules/RequestABrandContent/RequestABrandContent';
import MissingATransactionContent from '../../molecules/MissingATransactionContent/MissingATransactionContent';
import VideoLibraryContent from '../../molecules/VideoLibraryContent/VideoLibraryContent';
import './supportTabs.css';

const Container = styled('div', {
  width: '100%',
  maxWidth: '100%',
});

const SupportTabs: React.FC<SupportProps> = ({ tabs = [], defaultTab = 0, ...props }) => {
  const tabsList = [
    {
      label: 'FAQS',
      content: <FaqsContent />,
    },
    {
      label: 'VIDEOS LIBRARY',
      content: <VideoLibraryContent />,
    },
    {
      label: 'CONTACT SUPPORT',
      content: <ContactContent />,
    },
    {
      label: 'MISSING A TRANSACTION',
      content: <MissingATransactionContent />,
    },
    {
      label: 'REQUEST A BRAND',
      content: <RequestABrandContent />,
    },
  ];

  return (
    <Container className="supportTabs" {...props}>
      <Tabs
        className="supportTabs__tab"
        tabs={tabsList}
        defaultTab={defaultTab}
        css={{
          padding: '0 5px 10px',
          marginTop: '0',
        }}
      />
    </Container>
  );
};

export default SupportTabs;
