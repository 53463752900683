// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useState, useContext } from 'react'
import { styled, type ThemeProps } from '../../../theme/index'
import useCopy from '../../../hook/useCopy/useCopy'
import Heading from '../../atoms/Heading/Heading'
import Text from '../../atoms/Text/Text'
import { Button } from '../../atoms/Button/Button'
import CopyLink from '../../molecules/CopyLink/CopyLink'
import LinkGeneratorForm from '../../molecules/LinkGeneratorForm/LinkGeneratorForm'
import client from '../../api';
import { UserContext } from "../../../context/UserContext";
import { Link } from "react-router-dom";
import LoadingOverlay from "../../atoms/LoadingOverlay/LoadingOverlay";

interface Props extends ThemeProps {
  isBuildALink: boolean
  isHomePage: boolean
}

const Card = styled('div', {
  width: '100%',
  maxWidth: 'inherit',
  boxShadow: '0 1px 3px 0 rgba(77, 77, 77, 0.5)',
  backgroundColor: '$clear',
  padding: '20px 20px 30px'
})

const Head = styled(Heading, {
  color: '$blue3',
  fontFamily: '$deco',
  fontWeight: '$bold',
  letterSpacing: '0.93px',
  textTransform: 'capitalize',
  lineHeight: 1,
  fontSize: '24px',
  margin: '6px 4px 17px',
  '@bp3': {
    fontSize: '28px',
    margin: '5px 4px 18px'
  }
})

const ClearButton = styled(Button, {
  width: '100%',
  maxWidth: '360px',
  margin: '0 auto',
  marginTop: '50px'
})

const Disclaimer = styled(Text, {
  fontSize: '13px',
  letterSpacing: '0.3px',
  lineHeight: 1.571,
  marginBottom: '20px',
  marginLeft: '4px',
  '@bp3': {
    fontSize: '$size14',
    // marginBottom: '37px'
  }
})

const Label = styled(Text, {
  fontSize: '$size15',
  fontWeight: '$bold'
})

const Error = styled(Text, {
  fontSize: '$size16',
  fontWeight: '$bold',
  lineHeight: 1.42,
  fontFamily: '$label'
})

const Message = styled(Text, {
  fontSize: '$size16',
  lineHeight: 1.42,
  fontFamily: '$label',
  color: '$darkgray',
  marginTop: '40px',
  a: {
    color: '$black',
    textDecoration: 'none',
    '&:hover': {
      color: '#275153'
    }
  }
})

const YourLink = styled('input', {
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '12px',
  fontSize: '$size15',
  fontFamily: '$body',
  fontWeight: '$bold',
  lineHeight: 1.37,
  border: '1px solid $blue7',
  borderRadius: '1px',
  width: '100%',
  maxWidth: '100%',
  // maxWidth: '275px',
  minHeight: '44px',
  maxHeight: 'fit-content',
  margin: '10px 0',
  color: '#666',
  wordWrap: 'anywhere'
})

const ErrorMsg = styled('div', {
  fontFamily: '$label',
  color: '#a94442',
  border: '1px solid #ebccd1',
  backgroundColor: '#f2dede',
  borderRadius: '2px',
  padding: '15px 30px',
  textAlign: 'center',
  fontSize: '$size16'
});

const DeepLinkForm: React.FC<Props> = ({ isBuildALink = false, isHomePage = false, ...props }) => {
  const { userCredentials } = useContext(UserContext);
  const [deepLinkUrl, setDeepLinkUrl] = useState("");
  const [isShort, setIsShort] = useState(true);
  const [step, setStep] = useState(0);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [copyAction] = useCopy()

  // const [state, setState] = React.useState(0)
  const handleAction = (args: any): void => {
    let nextStep = true;
    setErrorMsg('');
    setLoading(true);

    // build a link
    if (step === 0) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const dest_url = args.url;
      if (dest_url !== "") {

        // set for the 'create short url' checkbox
        const shortLink = args.isShort;
        setIsShort(shortLink);

        // eslint-disable-next-line @typescript-eslint/naming-convention
        const aff_sub = args.id;

        // Access the user credentials
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { password, username, csrf_token } = userCredentials;
        void client.post("/api/deep_linking", { targetUrl: dest_url.trim(), short_link: shortLink, aff_sub }, {
          headers: { "Content-type": "application/x-www-form-urlencoded", csrf_token },
          auth: { username, password }
        })
          .then((response) => {
            if (response.status === 200 && response.data.status === undefined) {
              const responseData = response.data;
              if (response.data.url === "") {
                setErrorMsg("We were unable to create a link, please try again later or contact support for help.");
              }
              else {
                setErrorMsg("");
                setDeepLinkUrl(responseData.url);

                if (shortLink === true && responseData.url === responseData.url_long) {
                  setErrorMsg("We were unable to create a short link at the moment, please use the long link or try again later.");
                }
              }
            }
            else { // some error happened during the deeplinking
              switch (response.data.status) {
                // Chris: 2024-08-20 the 400 and 500 cases may not be needed, as it will never pass that status to success
                case 403:
                  setStep(4); // PRIVATE BRAND
                  break;
                case 404:
                case 410:
                  setStep(3); // THIS BRAND IS NOT CURRENTLY AVAILABLE ON BRANDCYCLE
                  break;
                case 406:
                  setStep(5); // NOT AUTHORIZED DEEP LINKS
                  break;
                case 490:
                  setErrorMsg(response.data.message);
                  setStep(6);  // others
                  break;
                case 500:
                default:
                  setErrorMsg(response.data.message);
                  setStep(5); // others
                  break;
              }
            }

            setLoading(false);
          })
          .catch(e => {
            switch (e.response.data.status) {
              case 403:
                setStep(4); // PRIVATE BRAND
                break;
              case 404:
              case 410:
                setStep(3); // THIS BRAND IS NOT CURRENTLY AVAILABLE ON BRANDCYCLE
                break;
              case 406:
                setStep(5); // NOT AUTHORIZED DEEP LINKS
                break;
              case 490:
                setErrorMsg(e.response.data.message);
                setStep(6);  // others
                break;
              case 500:
              default:
                // setErrorMsg(e.response.data.message);
                setErrorMsg("An error occurred, please contact support at support@brandcycle.com");
                setStep(5); // others
                break;
            }
            setLoading(false);
          });
      }
      else {
        setErrorMsg("Destination URL can not be empty");
        nextStep = false;
        setLoading(false);
      }
    }

    if (nextStep) {
      setStep(1);
    }
  }

  const output = [
    // 0 - Generate a custom deep link for any BrandCycle supported retailer
    <>
      {
        !isBuildALink && <Disclaimer>Generate a custom deep link for any BrandCycle supported brand</Disclaimer>
      }
      {errorMsg !== "" ? <ErrorMsg>{errorMsg}</ErrorMsg> : null}
      <LinkGeneratorForm
        type="deepLink"
        handler={handleAction}
        hasAction={false}
        isShort={isShort}
        isBuildALink={isBuildALink}
        enabledDeepLink={isHomePage}
      />
    </>,
    // 1 - Your custom link has been created.
    <>
      <Disclaimer>Your custom link has been created.</Disclaimer>
      {errorMsg !== "" ? <ErrorMsg>{errorMsg}</ErrorMsg> : null}
      <Label>Your Link</Label>
      <YourLink type="text" defaultValue={deepLinkUrl}></YourLink>
      <div
        onClick={() => {
          copyAction(deepLinkUrl)
        }}
      >
        <CopyLink />
      </div>
      <p style={{ fontSize: '13px', marginTop: '10px' }} >To ensure your link will track and commissions will be paid, please do not further alter this link before sharing it.</p>
      <ClearButton type="clear" onClick={() => {
        setDeepLinkUrl("");
        setErrorMsg("");
        setStep(0)
      }
      } >
        BUILD ANOTHER LINK
      </ClearButton>
    </>,
    // 2 - THIS BRAND IS NOT CURRENTLY AVAILABLE ON BRANDCYCLE
    <>
      <Error>THIS BRAND IS NOT CURRENTLY AVAILABLE ON BRANDCYCLE</Error>
      <ClearButton type="clear" onClick={() => {
        setDeepLinkUrl("");
        setErrorMsg("");
        setStep(0)
      }
      } >
        BUILD ANOTHER LINK
      </ClearButton>
      <Message>
        If you are having trouble building links or think you have received this message in error,
        please email our support team at <a href="mailto:support@brandcycle.com">support@brandcycle.com</a>.
      </Message>
    </>,
    // 3 - THIS BRAND IS NOT CURRENTLY AVAILABLE ON BRANDCYCLE
    <>
      <Error>THIS BRAND IS NOT CURRENTLY AVAILABLE ON BRANDCYCLE</Error>
      <ClearButton type="clear" onClick={() => {
        setDeepLinkUrl("");
        setErrorMsg("");
        setStep(0)
      }
      } >
        BUILD ANOTHER LINK
      </ClearButton>
      <Message>
        If you are having trouble building links or think you have received this message in error,
        please email our support team at <a href="mailto:support@brandcycle.com">support@brandcycle.com</a>.
      </Message>
    </>,
    // 4 - PRIVATE BRAND
    <>
      <Error>PRIVATE BRAND</Error>
      <Message>
        This Brand is private and requires special approval.
      </Message>
      <Link to="./brands">
        <div>
          See all other brands
        </div>
      </Link>
      <ClearButton type="clear" onClick={() => {
        setDeepLinkUrl("");
        setErrorMsg("");
        setStep(0)
      }
      } >
        BUILD ANOTHER LINK
      </ClearButton>
    </>,
    // 5 - NOT AUTHORIZED DEEP LINKS
    <>
      <Error>THE BRANDS HAS NOT AUTHORIZED DEEP LINKS FOR THIS DOMAIN.</Error>
      <Message>
        {/* The brand has not authorized deep links for this domain. */}
      </Message>
      <Link to="./brands">
        <div>
          See all other brands
        </div>
      </Link>
      <ClearButton type="clear" onClick={() => {
        setDeepLinkUrl("");
        setErrorMsg("");
        setStep(0)
      }
      } >
        BUILD ANOTHER LINK
      </ClearButton>
    </>,
    // 6 - Other errors
    <>
      <Error>
        {errorMsg}
      </Error>
      <Message>
        {errorMsg}
      </Message>
      <ClearButton type="clear" onClick={() => {
        setDeepLinkUrl("");
        setErrorMsg("");
        setStep(0)
      }
      } >
        BUILD ANOTHER LINK
      </ClearButton>
    </>,

  ]

  if (isBuildALink) {
    return (
      <div className="master-div">

        {loading && <LoadingOverlay style={{ position: "absolute", zIndex: "100" }} />}

        {output[step !== undefined ? step : 0]}
      </div>
    )
  }

  return (
    <div {...props}>

      {loading && <LoadingOverlay style={{ position: "absolute", zIndex: "100" }} />}

      <Card>
        <Head as="h3">Build a Link</Head>

        {output[step !== undefined ? step : 0]}
      </Card>
    </div>
  )
}

export default DeepLinkForm
