import React from 'react'
import { styled, type ThemeProps } from './../../../theme/index'
import Text from './../../atoms/Text/Text'
// import { Button } from './../../atoms/Button/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'

const Container = styled('div', {
  display: 'block',
  maxWidth: '100%',
  alignItems: 'center',
  svg: {
    marginRight: '10px'
  }
})

const Paragraph = styled(Text, {
  marginBottom: '25px',
  lineHeight: 1.562,
  letterSpacing: '-0.2px'
})

const ButtonWrapper = styled('div', {
  maxWidth: '193px',
  paddingBottom: '10px'
})

const Div = styled('div', {
  backgroundColor: '#595F7B',
  borderRadius: '1px !important',
  height: '44px',
  padding: '0px 28px !important',
  fontSize: '15px !important',
  fontWeight: 'bold',
  letterSpacing: '0.8px',
  lineHeight: '50px',
  backgroundImage: 'none',
  textTransform: 'uppercase',
  width: 'auto'
})

const Heading = styled('div', {
  fontFamily: '$heading',
  fontSize: '18px',
  fontWeight: '$bold',
  letterSpacing: '0.5px',
  lineHeight: 1,
  textTransform: 'uppercase',
  color: '$black',
  margin: '45px 0 20px'
})

const OrderedList = styled('ol', {
  paddingLeft: 0,
  margin: '0 0 20px',
  counterReset: 'item',
  listStyleType: 'none'
})

const ListElement = styled('li', {
  fontFamily: '$body',
  fontSize: '16px',
  lineHeight: 1.562,
  letterSpacing: '-0.2px',
  '&::marker': {
    fontWeight: '$bold'
  },
  '&:before': {
    fontWeight: '$bold',
    content: 'counter(item) "  "',
    counterIncrement: 'item',
    marginRight: '4px'
  }
})

const Link = styled('a', {
  color: '$black',
  '&:hover': {
    color: '#275153'
  }
})

const ToolsContent: React.FC<ThemeProps> = ({ ...props }) => {
  return (
    <Container {...props}>
      <Paragraph>
        <b><i>By the end of the year, we will be bidding farewell to our legacy Bookmarklet tool. We highly recommend making the switch to our new, improved <a style={{color: 'black'}} href="https://brandcycle.shop/chrome-extension" target="_blank" rel="noreferrer">Extension</a> for a smoother and more powerful experience!</i></b>
      </Paragraph>
      <Paragraph>
        BrandCycle’s Bookmarklet Tool is a simple yet powerful time-saving tool unique to the
        BrandCycle platform. Find everything you need all in one place without ever having to login
        to your BrandCycle account! Create deep-links and grab images, access all Retailer offers,
        campaign content and reports.
      </Paragraph>

      <Paragraph>
        Get the Bookmarklet tool by dragging the &apos;BrandCycle&apos; button below into your
        browser&apos;s bookmarks bar.
      </Paragraph>

      <ButtonWrapper>
        <Div>
          <a style={{color: 'white', textDecoration: 'none'}}
             href="javascript:(function () {var jsEm = document.createElement('script');document.body.appendChild(jsEm);jsEm.setAttribute('src', '//brandcycle.net/sites/all/modules/bc/bookmarklet/loader.php');}());"
           >
            <FontAwesomeIcon icon={faDownload} />
            BrandCycle
          </a>
        </Div>
      </ButtonWrapper>

      <Heading>INSTRUCTIONS</Heading>

      <OrderedList>
        <ListElement>
          Go to the brand* you want to create a deep link, grab images, offers or campaigns for.
        </ListElement>

        <ListElement>
          Click on the BrandCycle Bookmarklet from your bookmarks bar, and that will open the
          bookmarklet on the page.
        </ListElement>

        <ListElement>
          Copy short or full links, build an image link or directly share the short link on social
          media.
        </ListElement>
      </OrderedList>

      <Paragraph>Suggested browsers to use bookmarklet on are Chrome and Firefox.</Paragraph>

      <Paragraph>
        If you have any trouble adding the Bookmarklet tool to your browser, please{' '}
        <Link href="/support">contact support</Link>.
      </Paragraph>

      <Paragraph>
        *Bookmarklet may not be accessible on a few select brands&apos; sites. In these instances,
        please navigate to the &quot;Build a link&quot; page to create your link.
      </Paragraph>
    </Container>
  )
}

export default ToolsContent
