// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { FullStory, init as initFullStory } from '@fullstory/browser';
import Layout from '../components/templates/Layout/Layout';
import Login from './../components/templates/Login/Login';
import Logout from './Logout';
import Page404 from './Page404';
import Home from './../components/templates/Home/Home';
import Account from '../components/templates/Account/Account';
import UserEditInfo from '../components/templates/UserEditInfo/UserEditInfo';
import CancelAccount from '../components/templates/CancelAccount/CancelAccount';
import SubUsersPage from '../components/templates/SubUsers/SubUsers';
import AddSubUser from '../components/templates/SubUsersAdd/SubUsersAdd';
import SubUserDetails from '../components/templates/SubUsersDetails/SubUsersDetails';
import BrandsList from '../components/templates/BrandsList/BrandsList';
import BrandDetails from '../components/templates/BrandsDetails/BrandsDetails';
import SpecialPromotions from '../components/templates/SpecialPromotions/SpecialPromotions';
import Blog from '../components/templates/Blog/Blog';
import BlogSingle from '../components/templates/BlogSingle/BlogSingle';
import SupportTabs from '../components/templates/SupportTabs/SupportTabs';
import TermsAndConditionsOfUse from '../components/molecules/TermsandConditionsofUse/TermsAndConditionsOfUse';
import Tools from '../components/templates/Tools/Tools';
import Reports from '../components/templates/Reports/Reports';
import PrivacyPolicy from '../components/molecules/PrivacyPolicy/PrivacyPolicy';
import { RequestABrandBMK } from './RequestABrandBMK';
import BrandsDetailsBMK from '../components/templates/BrandsDetailsBMK/BrandsDetailsBMK';
import UserEditForgotPass from '../components/templates/UserEditInfo/UserEditForgotPass';
import './App.css';

ReactGA.initialize('G-8J4Z1B5S10', {
  gaOptions: {
    cookieFlags: 'SameSite=None;Secure',
  },
});

// FullStory
initFullStory({ orgId: 'o-1NCD85-na1' });

let uid = '';
let displayName = '';
let email = '';
let affId = '';
if (window.localStorage.getItem('user_credit') !== null) {
  const userCred = JSON.parse(window.localStorage.getItem('user_credit'));
  // console.log(userCred.user);
  uid = userCred.user.uid;
  displayName = userCred.user.name;
  email = userCred.user.email;
  affId = userCred.user.affId;
}

FullStory('setIdentity', {
  uid,
  properties: {
    displayName,
    email,
    affId,
  },
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function Auth(): boolean {
  return window.localStorage.getItem('user_credit') !== null;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function App() {
  const [data, setData] = useState(null);
  const auth = Auth();
  const url = window.location.pathname;

  if (url.includes('/bmk')) {
    // FS set event for BMK
    void FullStory('trackEvent', {
      name: 'bookmarklet open',
    });
    window._fs_is_outer_script = true;
  }

  if (url.includes('/bmk') && localStorage.getItem('isBookmarklet') === null) {
    window.localStorage.setItem('isBookmarklet', url);
  } else if (!url.includes('/bmk') && localStorage.getItem('isBookmarklet') !== null) {
    window.localStorage.removeItem('isBookmarklet');
    window.localStorage.removeItem('globals');
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const sendData = (data) => {
    setData(data);
  };

  if (!auth) {
    if (localStorage.getItem('isBookmarklet') !== null) {
      window.addEventListener(
        'message',
        (event) => {
          if (event.data.action === 'request-site-info') {
            const data = event.data.value;
            localStorage.setItem('parentUrl', data.target);
            localStorage.setItem('images', JSON.stringify(data.websiteInfo.images));
          }
        },
        true,
      );

      if (url.includes('/brands/') || url.includes('/request-a-brand/bmk')) {
        window.location.href = '/login/bmk';
      }

      return (
        <>
          <Routes>
            <Route>
              <Route path="/login/bmk" element={<Login />} />
            </Route>
          </Routes>
        </>
      );
    }

    return (
      <>
        <Routes>
          <Route>
            <Route index element={<Login />} />
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/user/reset/:uid/:dateExpire/:resetToken"
              element={<UserEditForgotPass />}
            />

            <Route path="*" exact={true} element={<Login />} />
          </Route>
        </Routes>
      </>
    );
  }

  let userDetails = window.localStorage.getItem('user_credit', {});
  if (userDetails.length > 150) {
    userDetails = JSON.parse(userDetails);

    if (userDetails.user.terms !== 1) {
      return (
        <Layout menusData={data}>
          <Routes>
            <Route>
              <Route path="*" element={<TermsAndConditionsOfUse isAccept={false} />} />
            </Route>
          </Routes>
        </Layout>
      );
    }

    if (userDetails.user.privacy !== 1) {
      return (
        <Layout menusData={data}>
          <Routes>
            <Route>
              <Route path="*" element={<PrivacyPolicy showCheck={true} lockValue={false} />} />
            </Route>
          </Routes>
        </Layout>
      );
    }
  }

  // bookmarklet Routes
  if (auth && localStorage.getItem('isBookmarklet') !== null) {
    const role = localStorage.getItem('level');
    let menuData = {};
    switch (role) {
      case 'admin':
      case 'account manager':
        menuData = {
          side: [
            {
              label: 'BRANDS',
              src: '/brands',
            },
            {
              label: 'PROMOTIONS',
              src: '/special-promotion',
            },
            {
              label: 'REPORTS',
              items: [
                {
                  label: 'SUMMARY REPORT',
                  src: '/reports/summary',
                },
                {
                  label: 'BRANDS REPORT',
                  src: '/reports/brands',
                },
                {
                  label: 'CONVERSION REPORT',
                  src: '/reports/conversion',
                },
                {
                  label: 'TRAFFIC REFERRALS REPORT',
                  src: '/reports/traffic',
                },
                {
                  label: 'PAYMENT REPORT',
                  src: '/reports/payment',
                },
              ],
            },
            {
              label: 'TOOLS',
              items: [
                {
                  label: 'BOOKMARKLET TOOL',
                  src: '/bookmarklet',
                },
                {
                  label: 'BUILD A LINK',
                  src: '/build-a-link',
                },
                {
                  label: 'MOBILE APP',
                  src: '/mobile-app',
                },
              ],
            },
            {
              label: 'BLOG',
              src: '/blog',
            },
            {
              label: 'SUPPORT',
              src: '/faq',
            },
          ],
        };
        break;
      case 'affiliate':
        menuData = {
          side: [
            {
              label: 'BRANDS',
              src: '/brands',
            },
            {
              label: 'REPORTS',
              items: [
                {
                  label: 'SUMMARY REPORT',
                  src: '/reports/summary',
                },
                {
                  label: 'BRANDS REPORT',
                  src: '/reports/brands',
                },
                {
                  label: 'CONVERSION REPORT',
                  src: '/reports/conversion',
                },
                {
                  label: 'TRAFFIC REFERRALS REPORT',
                  src: '/reports/traffic',
                },
                {
                  label: 'PAYMENT REPORT',
                  src: '/reports/payment',
                },
              ],
            },
          ],
        };
        break;
      default:
        menuData = {
          side: [
            {
              label: 'BRANDS',
              src: '/brands',
            },
          ],
        };
        break;
    }

    return (
      <Layout menusData={menuData} bmk={true}>
        <Routes>
          <Route path="/brands/:id/bmk" element={<BrandsDetailsBMK />} />
          <Route path="/request-a-brand/bmk" element={<RequestABrandBMK />} />

          <Route path="*" exact={true} element={<Page404 />} />
        </Routes>
      </Layout>
    );
  }

  return (
    <Layout menusData={data}>
      <Routes>
        <Route path="/" element={<Home sendData={sendData} />} />
        <Route path="/login" element={<Login />} />
        <Route path="/home" element={<Home sendData={sendData} />} />

        <Route path="/account" element={<Account />} />
        <Route path="/user/edit" element={<UserEditInfo />} />
        <Route path="/user/cancel" element={<CancelAccount />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditionsOfUse />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/logout" element={<Logout />} />

        <Route path="/subusers" element={<SubUsersPage />} />
        <Route path="/subusers/add" element={<AddSubUser />} />
        <Route path="/subusers/edit/:uid" element={<SubUserDetails />} />

        <Route path="/brands" element={<BrandsList />} />
        <Route path="/retailers" element={<BrandsList />} />
        <Route path="/brands/:id" element={<BrandDetails />} />
        <Route path="/merchant/merchant/:id" element={<BrandDetails />} />
        {/* <Route path="/brands/:id/bmk" element={<BrandsDetailsBMK />} /> */}

        <Route path="/special-promotion" element={<SpecialPromotions />} />

        <Route path="/tools" element={<Tools defaultTab="0" />} />
        <Route path="/browser-extension" element={<Tools defaultTab="0" />} />
        <Route path="/extension" element={<Tools defaultTab="0" />} />
        <Route path="/build-a-link-new" element={<Tools defaultTab="1" />} />
        <Route path="/build-a-link" element={<Tools defaultTab="1" />} />
        <Route path="/mobile-app" element={<Tools defaultTab="2" />} />
        <Route path="/bookmarklet" element={<Tools defaultTab="3" />} />

        <Route path="/blog" element={<Blog />} />
        <Route path="/blog-post/:id" element={<BlogSingle />} />

        <Route path="/reports/summary" element={<Reports defaultTab="0" />} />
        <Route path="/reports/brands" element={<Reports defaultTab="1" />} />
        <Route path="/reports/conversion" element={<Reports defaultTab="2" />} />
        <Route path="/reports/traffic" element={<Reports defaultTab="3" />} />
        <Route path="/reports/payment" element={<Reports defaultTab="4" />} />
        <Route path="/reports/payment-balance" element={<Reports defaultTab="5" />} />
        <Route path="/reports/referral-links" element={<Reports defaultTab="6" />} />

        <Route path="/faq" element={<SupportTabs defaultTab={0} />} />
        <Route path="/faqs" element={<SupportTabs defaultTab={0} />} />
        <Route path="/videos-library" element={<SupportTabs defaultTab={1} />} />
        <Route path="/video-library" element={<SupportTabs defaultTab={1} />} />
        <Route path="/contact-support" element={<SupportTabs defaultTab={2} />} />
        <Route path="/support" element={<SupportTabs defaultTab={2} />} />
        <Route path="/missing-a-transaction" element={<SupportTabs defaultTab={3} />} />
        <Route path="/request-a-brand" element={<SupportTabs defaultTab={4} />} />
        <Route path="/brand_request" element={<SupportTabs defaultTab={4} />} />

        <Route path="*" exact={true} element={<Page404 />} />
      </Routes>
    </Layout>
  );
}

export default App;
