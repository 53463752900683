import React from "react";
import { styled } from "../theme/index";
import { Link } from "react-router-dom";

const Error = styled("div", {
  fontFamily: "$label",
  color: "#a94442",
  border: "1px solid #ebccd1",
  backgroundColor: "#f2dede",
  borderRadius: "2px",
  padding: "15px 30px",
  fontSize: "$size16",
});

// TODO - temporary fix for ErrorMessage type errors. avoided the more thourough solution to avoid a larger test plan
// – right now to pass an error message with the default message <ErrorMessage contactSupport={true} message="" />
// – to fix, the `message` prop should be the only prop, with the `contactSupport` content set as the default value in a variable. the `message` prop type should be `string | JSX.Element` (to allow for the `Link` components inside text)
interface ErrorMessageProps {
  contactSupport?: boolean;
  message?: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({
  contactSupport = false,
  message,
}) => {
  if (contactSupport) {
    return (
      <Error>
        Something went wrong, please try again later or{' '}
        <Link to="/contact-support">contact support</Link>
      </Error>
    );
  }

  return <Error>{message}</Error>;
};

export default ErrorMessage;
