import React from 'react';
import './browserExtensionContent.css';

const BrowserExtensionContent: React.FC = () => {
  return (
    <section className="contentChromeExtension">
      <h3 className="contentChromeExtension__headline3">BrandCycle Extension</h3>
      <p className="contentChromeExtension__body">
        Create affiliate links in a flash! With the BrandCycle Chrome and Edge Extension, you can
        effortlessly generate deep links with just one click. Say goodbye to tab-hopping – simply
        click the BrandCycle icon to instantly get your custom tracking link!
      </p>
      <h4 className="contentChromeExtension__headline4">How to Install:</h4>
      <ol className="contentChromeExtension__list">
        <li className="contentChromeExtension__listItem">
          <a
            className="contentChromeExtension__link"
            href="https://brandcycle.shop/chrome-extension"
            target="_blank"
            rel="noreferrer"
          >
            Install the BrandCycle Extension
          </a>{' '}
          from the Chrome Web Store.
        </li>
        <li className="contentChromeExtension__listItem">Click “Add to Chrome” for Chrome browser or “Get” for Edge browser.</li>
        <li className="contentChromeExtension__listItem">
          Accept the permissions prompt. 
        </li>
        <li className="contentChromeExtension__listItem">
          Log in to your BrandCycle account.
        </li>
      </ol>
      <h4 className="contentChromeExtension__headline4">How to Use:</h4>
      <ol className="contentChromeExtension__list">
        <li className="contentChromeExtension__listItem">Visit any brand’s website.</li>
        <li className="contentChromeExtension__listItem">
          Click the BrandCycle icon in your extension toolbar or right-click on the page and select
          &quot;Generate BrandCycle affiliate link&quot;.{' '}
          <em>(If you&apos;re not logged in, you&apos;ll be prompted to log in.)</em>
        </li>
        <li className="contentChromeExtension__listItem">
          Copy your affiliate link and start sharing!
        </li>
      </ol>
      <hr className="contentChromeExtension__separator" />
      <em>
        For any issues, please contact{' '}
        <a className="contentChromeExtension__link" href="mailto:support@brandcycle.com">
          support@brandcycle.com
        </a>
      </em>
    </section>
  );
};

export default BrowserExtensionContent;