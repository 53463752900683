import React, { useEffect, useState, useContext, useRef } from 'react';
import type { HubSpotFormProps } from '../../../types/supportTabsTypes';
import ErrorMessage from '../../ErrorMessage';
import LoadingOverlay from '../../atoms/LoadingOverlay/LoadingOverlay';
import { UserContext } from '../../../context/UserContext';
import client from '../../api';

export interface Hbspt {
  forms: {
    create: (options: {
      region: string;
      portalId: string;
      formId: string;
      target: string;
      onFormReady?: (form: HTMLFormElement) => void;
    }) => void;
  };
}

export interface CollectedUserData {
  affiliateID: string;
  uid: string;
  email: string;
  userName: string;
  firstName: string;
  lastName: string;
}

const HubSpotForm: React.FC<HubSpotFormProps> = ({
  className,
  formId,
  portalId,
  region,
  targetId,
}) => {
  const [errorMessages, setErrorMessages] = useState(false);
  const [loading, setLoading] = useState(true);
  const { userCredentials } = useContext(UserContext);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { password = '', username = '', csrf_token = '' } = userCredentials;
  const [collectedUserData, setUserData] = useState<CollectedUserData | null>(null);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const formContainerRef = useRef<HTMLDivElement>(null);

  // fetches user data when credentials are available
  useEffect(() => {
    if (Boolean(password) && Boolean(username) && Boolean(csrf_token)) {
      client
        .get<CollectedUserData>('/api/support/get/details?_format=json', {
          headers: { csrf_token },
          auth: { username, password },
        })
        .then(({ data }) => {
          setUserData(data);
        })
        .catch((e) => {
          console.error('Error fetching user data:', e);
          setErrorMessages(true);
          setLoading(false);
        });
    } else {
      console.warn('Missing credentials, skipping user data fetch');
    }
  }, [password, username, csrf_token]);

  // loads the hubspot script
  useEffect(() => {
    if (!scriptLoaded) {
      const script = document.createElement('script');
      script.src = 'https://js.hsforms.net/forms/v2.js';
      script.async = true;

      script.onload = () => {
        setScriptLoaded(true);
      };

      script.onerror = () => {
        console.error('Error loading HubSpot forms script');
        setErrorMessages(true);
        setLoading(false);
      };

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script); // cleanup
      };
    }
  }, [scriptLoaded]);

  // query form fields + populate with user data
  useEffect(() => {
    if (scriptLoaded && collectedUserData != null) {
      const hbspt = (window as unknown as { hbspt: Hbspt }).hbspt;

      if (hbspt?.forms !== null) {
        hbspt.forms.create({
          region,
          portalId,
          formId,
          target: `#${targetId}`,
          onFormReady: () => {
            const iframe = formContainerRef.current?.querySelector('iframe');

            if (iframe?.contentWindow != null) {
              const iframeDoc = iframe.contentWindow.document;
              const emailField = iframeDoc.querySelector<HTMLInputElement>('input[name="email"]');
              const usernameField =
                iframeDoc.querySelector<HTMLInputElement>('input[name="username"]');
              const firstNameField =
                iframeDoc.querySelector<HTMLInputElement>('input[name="firstname"]');
              const lastNameField =
                iframeDoc.querySelector<HTMLInputElement>('input[name="lastname"]');
              const affiliateIdField = iframeDoc.querySelector<HTMLInputElement>(
                'input[name="affiliate_id"]',
              );
              const bcPortalIdField = iframeDoc.querySelector<HTMLInputElement>(
                'input[name="bc_portal_id"]',
              );

              if (emailField != null) emailField.value = collectedUserData?.email ?? '';
              if (usernameField != null) usernameField.value = collectedUserData?.userName ?? '';
              if (firstNameField != null) firstNameField.value = collectedUserData?.firstName ?? '';
              if (lastNameField != null) lastNameField.value = collectedUserData?.lastName ?? '';
              if (affiliateIdField != null)
                affiliateIdField.value = collectedUserData.affiliateID ?? '';
              if (bcPortalIdField != null) bcPortalIdField.value = collectedUserData?.uid ?? '';
            }
          },
        });

        setLoading(false);
      } else {
        console.error('HubSpot forms library is not loaded.');
        setErrorMessages(true);
        setLoading(false);
      }
    }
  }, [scriptLoaded, collectedUserData, region, portalId, formId, targetId]);

  return (
    <div ref={formContainerRef} id={targetId} className={`hubSpotForm ${className}`}>
      {loading && <LoadingOverlay />}
      {errorMessages && <ErrorMessage contactSupport={true} />}
    </div>
  );
};

export default HubSpotForm;
